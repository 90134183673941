import { Injectable, inject } from '@angular/core';
import { ActivatedRoute, ActivatedRouteSnapshot, NavigationEnd, Router, RoutesRecognized } from '@angular/router';
import { RouteConfig } from '../models/config.model';
import { constants } from '../../utils/constants';
import { ConfigService } from './config.service';
import { ResourceService } from './resource.service';
import { Meta, Title } from '@angular/platform-browser';
import { filter } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class RoutingService {
    router = inject(Router);
    configService = inject(ConfigService);
    resourceService = inject(ResourceService);
    titleService = inject(Title);
    metaService = inject(Meta);

    firstRouterCycleDone: boolean = false;
    hasUserCredentialsTimeout: ReturnType<typeof setTimeout>;

    public init(): Promise<void> {
        return new Promise((resolve) => {
            this.router.events.subscribe((event) => {
                if (event instanceof RoutesRecognized) {
                    let route = event.state.root.firstChild;
                    if (route) {
                        while (route.firstChild) {
                            route = route.firstChild;
                        }
                        if (this.firstRouterCycleDone) {
                            this.handleRouteChange(route);
                        } else {
                            this.firstRouterCycleDone = true;
                            const routeConfig: RouteConfig = route.data?.config;
                            if (routeConfig.public) {
                                this.initPublicRoute(route).then(() => resolve());
                            } else {
                                this.initPrivateRoute(route).then(() => resolve());
                            }
                        }
                    }
                }
            });
        });
    }

    private async initPublicRoute(route: ActivatedRouteSnapshot): Promise<void> {
        const promises: Promise<void>[] = [];
        return Promise.all(promises).then(() => {
            // this.initResources(false);
            if (this.configService.serverConfig().portal === 'public' && window.location.pathname === '/') {
                this.router.navigate([]);
            }
            this.handleRouteChange(route);
        });
    }

    private async initPrivateRoute(route: ActivatedRouteSnapshot) {
        // const user = await this.authService.fetchUser();
        const promises: Promise<void>[] = [];
        // promises.push(this.languageService.init(user.language));
        return Promise.all(promises).then(() => {
            // this.initResources(true);
            this.handleRouteChange(route);
        });
    }

    // private initResources(authenticated: boolean) {

    //     if (authenticated) {
    //         // authenticated resources
    //     }
    //     if (!authenticated) {
    //         // unauthenticated resources
    //     }
    //     // shared resources
    //     this.resourceService.addResource('language', languages);
    // }

    private handleRouteChange(route: ActivatedRouteSnapshot) {
        while (route.firstChild) {
            route = route.firstChild;
        }
        const routeConfig: RouteConfig = route.data?.config;

        const appTitle = 'Medicijnenmeldpunt';
        const appDescription = 'APP_DESCRIPTION';
        let routeTitle: string | undefined = routeConfig?.title;

        // set title
        if (routeTitle !== 'async') {
            if (routeTitle) this.titleService.setTitle(`${appTitle}`);
        }

        // set meta description
        this.metaService.addTag({
            name: 'description',
            content: appDescription
        });

        // set robots
        this.metaService.addTag({
            name: 'robots',
            content: routeConfig.robots ?? 'noindex'
        });

        // set route config
        this.configService.routeConfig.set(routeConfig);

        // set show / hide captcha
        if (route?.data?.captcha) {
            document.body.classList.add('show-captcha');
        } else {
            document.body.classList.remove('show-captcha');
        }
    }
}
