<div class="medicine-form" id="ervaring-delen">

    <div class="d-flex gap-32 flex-column saving--pre" [class.saving]="loading">

        @if (!FORM.meldingAangemaakt) {
            <div class="position-relative responsive-typeahead field">
                <span class="field-label">Welk medicijn heb je gebruikt?</span>
                
                <input
                    class="responsive-typeahead form-control"
                    [class.error-field]="validation()?.medicijn"
                    [placeholder]="'Zoek hier je medicijn (bv. Ibuprofen)'"
                    id="typeahead-focus"
                    type="text"
                    [(ngModel)]="FORM.medicijn"
                    (selectItem)="selectMedicijn($event.item)"
                    [ngbTypeahead]="search"
                    [selectOnExact]="true"
                    #medicineTypeaheadRef="ngbTypeahead"
                    (focus)="focus$.next($any($event).target.value)"
                    (click)="click$.next($any($event).target.value)"
                />
                @if (validation()?.medicijn) {
                    <span class="error-field-display">{{validation()?.medicijn}}</span>
                }
            </div>
    
            <div class="field">
                <span class="field-label">Hoe was je ervaring</span>
                <div class="experience-indicator-choices">
                    @for (experienceIcon of experienceScoreOptions; track $index) {
                        <button (click)="selectExperience($index + 1)" class="experience-indicator" [class.active]="FORM.ervaringIndicator === $index + 1">
                            
                                <i class="icon experience-score experience-score--{{$index + 1}} filled"></i>
                            
                                <ng-lottie 
                                    id="experience-score-{{$index + 1}}"
                                    class="lottie-animation"
                                    [options]="experienceIcon" 
                                    width="48px" 
                                    height="48px" 
                                    (animationCreated)="animationCreated($event)" 
                                    (mouseover)="animationItem?.[$index]?.play()"
                                    (mouseleave)="animationItem?.[$index]?.stop()"
                                />
                        </button>
                    }
                </div>
                @if (validation()?.ervaringIndicator) {
                    <span class="error-field-display">{{validation()?.ervaringIndicator}}</span>
                }
            </div>
            
            <div>
                <app-field-textarea
                    [label]="'Wat heb je ervaren bij het gebruiken ervan?'"
                    [model]="FORM.ervaringDescr"
                    [placeholder]="'Schrijf hier je ervaring...'"
                    [error]="validation()?.ervaringDescr"
                    (modelChange)="FORM.ervaringDescr = $event"
                ></app-field-textarea>
            </div>
        } @else if(!FORM.meldingAangevuld) {
            <div class="note note--success">
                <span>Hartelijk dank! We hebben je melding goed ontvangen. Deze zal, na controle, binnen enkele dagen op de website staan.</span>
            </div>
            <p>Vul onderstaande gegevens aan om beter inzicht te krijgen in je ervaring met het medicijn. Dit is niet verplicht.</p>

            <div>
                <app-field-checkboxes
                    [label]="'Mijn ervaring gaat over'"
                    [options]="medicijnOnderwerpOptions"
                    [model]="FORM.onderwerp ?? []"
                    [stacked]="true"
                    [classes]="'transparent large-checkbox checkbox-white'"
                    [error]="validation()?.overWerking || validation()?.overBijwerkingen || validation()?.overPraktisch"
                    (modelChange)="FORM.onderwerp = $event"
                ></app-field-checkboxes>
            </div>
            <div>
                <app-field-text
                    [label]="'Je leeftijd'"
                    [model]="FORM.leeftijd"
                    [type]="'integer'"
                    [afterString]="'jaar'"
                    [inputWidth]="'100px'"
                    [instant]="true"
                    [error]="validation()?.leeftijd"
                    (modelChange)="FORM.leeftijd = $any($event)"
                ></app-field-text>
            </div>
            <div>
                <app-field-radios
                    [label]="'Geslacht'"
                    [options]="genderOptions"
                    [model]="FORM.geslacht"
                    [classes]="'transparant large-radio'"
                    [stacked]="true"
                    [error]="validation()?.geslacht"
                    (modelChange)="FORM.geslacht = $event"
                ></app-field-radios>
            </div>
            <div>
                <app-field-text
                [label]="'Voor welke klacht of aandoening gebruikte u dit medicijn?'"
                [model]="FORM.redenGebruik"
                [type]="''"
                [instant]="true"
                [error]="validation()?.redenGebruik"
                (modelChange)="FORM.redenGebruik = $any($event)"
            ></app-field-text>
            </div>
            <div>
                <app-field-radios
                    [label]="'Na deze ervaring ben ik'"
                    [options]="decisionOptions"
                    [model]="FORM.beslissing"
                    [classes]="'transparant large-radio'"
                    [stacked]="true"
                    [error]="validation()?.beslissing"
                    (modelChange)="FORM.beslissing = $event"
                ></app-field-radios>
            </div>
            <div>
                <app-field-radios
                    [label]="'Ik doe deze melding als zorgverlener voor een patiënt'"
                    [options]="yesNoOptions"
                    [model]="FORM.hulpverlener"
                    [classes]="'transparant large-radio'"
                    [stacked]="true"
                    [error]="validation()?.hulpverlener"
                    (modelChange)="FORM.hulpverlener = $event"
                ></app-field-radios>
            </div>

            <div>
                <app-field-single-checkbox
                    [(model)]="FORM.privacyPolicy"
                    [error]="validation()?.privacyPolicy"
                >
                <span class="cursor-pointer">Ik ga akkoord met te <a href="/privacybeleid" (click)="$event.stopPropagation();" target="_blank" class="link-color">privacyverklaring</a></span>
            </app-field-single-checkbox>
            </div>
        }
        
        <ng-content></ng-content>
    </div>
</div>