import { Directive, HostListener, inject, Injectable, OnDestroy } from '@angular/core';
import {
    BehaviorSubject,
    debounceTime,
    distinctUntilChanged,
    fromEvent,
    map,
    Observable,
    shareReplay,
    Subject
} from 'rxjs';

export enum SCREEN_SIZE {
    mobile = 'mobile',
    tablet = 'tablet',
    desktop = 'desktop'
}

@Injectable({
    providedIn: 'root'
})
export class ResponsiveService implements OnDestroy {
    // private resizeSubject: Subject<SCREEN_SIZE>;
    // private resizeSubject: Subject<SCREEN_SIZE> = new Subject();
    private resizeSubject: BehaviorSubject<SCREEN_SIZE> = new BehaviorSubject(this.getScreenSize(window.innerWidth));
    private resizeListener$: Observable<Event> = fromEvent(window, 'resize');

    constructor() {
        this.resizeListener$
            .pipe(
                debounceTime(200), // Debounce to limit event frequency
                map(() => this.getScreenSize(window.innerWidth))
            )
            .subscribe((screenSize) => {
                this.resizeSubject.next(screenSize);
            });
    }

    public getScreenSize(width: number) {
        let screenSize: SCREEN_SIZE;
        if (width <= 768) {
            screenSize = SCREEN_SIZE.mobile;
        } else if (width <= 1024) {
            screenSize = SCREEN_SIZE.tablet;
        } else {
            screenSize = SCREEN_SIZE.desktop;
        }
        return screenSize;
    }

    get onResize$(): Observable<SCREEN_SIZE> {
        return this.resizeSubject.asObservable().pipe(debounceTime(200), distinctUntilChanged(), shareReplay(1));
    }

    ngOnDestroy() {
        // Clean up listeners
        this.resizeSubject.complete();
    }
}
