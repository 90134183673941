import { provideHttpClient, withInterceptorsFromDi, HTTP_INTERCEPTORS, withFetch } from '@angular/common/http';
import { ApplicationConfig, ErrorHandler, LOCALE_ID, importProvidersFrom, isDevMode } from '@angular/core';
import { ApiInterceptor } from './shared/interceptors/api.interceptor';
import { ErrorService } from './shared/services/error.service';
import { constants } from './utils/constants';
import { ApiModule, Configuration } from './utils/api';

export const appConfig: ApplicationConfig = {
    providers: [
        // angular
        provideHttpClient(withInterceptorsFromDi(), withFetch()),
        // plugins
        importProvidersFrom(
            ApiModule.forRoot(() => {
                return new Configuration({ basePath: '/api' });
            })
        ),
        // interceptors
        { provide: HTTP_INTERCEPTORS, useClass: ApiInterceptor, multi: true },
        // error handler
        { provide: ErrorHandler, useClass: ErrorService }
    ]
};
